import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Colours from '../utils/colours';
import MediaQueries from '../utils/media-queries';
//import { Link } from 'gatsby';

const SectionContainer = styled.section`
  background: ${Colours.offWhite};
  color: ${Colours.offBlack};
  margin: 0 auto;
  width: 100%;
`;
const SectionContents = styled.section`
  background: ${Colours.offWhite};
  margin: 0 auto;
  max-width: 1200px;
  padding: 2rem 6vw 12rem;
  display: flex;
  flex-direction: column;
  img {
    align-self: center;
    width: 300px;
    padding: 0 0 2rem;
    margin-top: 0;
  }
  a {
    color: ${Colours.offBlack} !important;
    position: relative;
    display: block;
    align-self: center;
    width: max-content;
    z-index: 1;
    color: inherit;
    font-size: 1.5rem;
    font-weight: bolder;
    font-family: Catamaran, sans-serif;
    background: ${Colours.secondary2};
    padding: 0.25rem 1.5rem;
    border-radius: 0.5rem;
    text-decoration: none;
  }
  @media only screen and (max-width: ${MediaQueries.fish}) {
    padding: 2rem 3vw 5rem;
  }
`;

const ContactGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'form info';
  @media only screen and (max-width: ${MediaQueries.insect}) {
    grid-template-columns: 1fr;
    grid-template-areas: 'info' 'form';
  }
`;

const HelpText = styled.h3`
  font-size: calc(0.8rem + 2vw);
  font-weight: 900;
  padding: 0 0 0.5rem;
`;

const MyForm = styled.form`
  font-size: 0.8rem;
  max-width: 550px;

  input,
  select {
    display: block;
    border: none;
    margin-bottom: 1rem;
  }
  select {
    width: 50%;
  }
  textarea {
    display: block;
    width: 100%;
    height: 12rem;
    border: none;
    margin-bottom: 1rem;
  }
  button {
    color: ${Colours.offBlack} !important;
    position: relative;
    display: block;
    float: right;
    width: max-content;
    z-index: 1;
    color: inherit;
    font-size: 1rem;
    font-weight: bolder;
    font-family: Catamaran, sans-serif;
    background: ${Colours.secondary2};
    padding: 0.25rem 1.5rem;
    border-radius: 0.5rem;
    text-decoration: none;
    border: none;
    :hover {
      cursor: pointer;
    }
  }
  @media only screen and (max-width: ${MediaQueries.insect}) {
    margin: 0 auto;
  }
`;

const SomeInfo = styled.div`
  margin-left: 4rem;
  @media only screen and (max-width: ${MediaQueries.insect}) {
    margin: 0 auto;
  }
`;

export default ({ q }) => {
  return (
    <>
      <Helmet>
        <link
          href='https://fonts.googleapis.com/css2?family=Parisienne&display=swap'
          rel='stylesheet'
        ></link>
      </Helmet>
      <SectionContainer>
        <SectionContents>
          <HelpText id='howcanIhelp'>How can I help?</HelpText>
          <ContactGridContainer>
            <SomeInfo style={{ gridArea: 'info' }}>
              <p>
                Fill out the form and I'll get back to you to discuss your project and how I can
                help you.
              </p>
              <p>
                Alternatively you can send an email straight to josh@joshlong.design and let me know
                what your project is.
              </p>
              <p>I look forward to working with you!</p>
              <p style={{ fontSize: '2rem', fontFamily: 'Parisienne, Catamaran, sans serif' }}>
                Josh
              </p>
              <p style={{ marginTop: '4rem', textAlign: 'right' }}>
                If you need design, count me in!
              </p>
            </SomeInfo>
            <div style={{ gridArea: 'form' }}>
              <MyForm
                name='contact'
                method='POST'
                netlify-honeypot='supersecretspamfilter'
                netlify
                action='/contact?success'
              >
                <input type='hidden' name='form-name' value='contact' />
                <input name='supersecretspamfilter' type='hidden' />
                <label htmlFor='contactname'>Name*</label>
                <input type='text' name='contactname' placeholder='Enter your name' required />
                <label htmlFor='contactemail'>Email*</label>
                <input
                  type='email'
                  name='contactemail'
                  placeholder='Enter your email address'
                  size='30'
                  required
                />
                <label htmlFor='businessname'>Business Name</label>
                <input
                  type='text'
                  name='businessname'
                  placeholder='What is the name of your business?'
                  size='35'
                />
                <label htmlFor='industry'>Industry</label>
                <input
                  type='text'
                  name='industry'
                  placeholder='What industry are you in?'
                  size='20'
                />

                <label>
                  What type of design do you need?{' '}
                  <select name='type[]' multiple>
                    {q === '?product' ? (
                      <option value='product' selected='selected'>
                        Product Design
                      </option>
                    ) : (
                      <option value='product'>Product Design</option>
                    )}
                    {q === '?graphic' ? (
                      <option value='graphic' selected='selected'>
                        Graphic Design
                      </option>
                    ) : (
                      <option value='graphic'>Graphic Design</option>
                    )}
                    {q === '?web' ? (
                      <option value='web' selected='selected'>
                        Web Design
                      </option>
                    ) : (
                      <option value='web'>Web Design</option>
                    )}
                    <option value='notsure'>I'm not sure</option>
                  </select>
                </label>

                <label htmlFor='message'>Let me know about your project*</label>
                <textarea
                  name='message'
                  placeholder='What do you need help with?'
                  maxLength='300'
                  required
                ></textarea>

                <p>
                  <button type='submit'>Send</button>
                </p>
              </MyForm>
              <p style={{ fontSize: '0.8rem' }}>*Required</p>
            </div>
          </ContactGridContainer>
        </SectionContents>
      </SectionContainer>
    </>
  );
};
