import React from 'react';
import { Location } from '@reach/router';
import Layout from '../components/Layout';
import NeedADesignerContact from '../components/NeedADesignerContact';
import ContactFormSection from '../components/ContactFormSection';
//import styled from 'styled-components';
import SEO from '../components/SEO/SEO';

export default () => {
  return (
    <Layout>
      <SEO title='Get in touch' pathname='/contact/' />
      <Location>
        {({ location }) => {
          return (
            <>
              <NeedADesignerContact q={location.search} h={location.hash} />
              <ContactFormSection q={location.search} />
            </>
          );
        }}
      </Location>
    </Layout>
  );
};
