import React from 'react';
import { Location } from '@reach/router';
import styled from 'styled-components';
import Colours from '../utils/colours';
import MediaQueries from '../utils/media-queries';
import { Link } from 'gatsby';

const SectionContainer = styled.section`
  background: ${Colours.white};
  color: ${Colours.offBlack};
  margin: 0 auto;
  width: 100%;
  @media only screen and (max-width: ${MediaQueries.insect}) {
    margin: -0.2rem auto 0;
  }
`;
const SectionContents = styled.section`
  background: ${Colours.white};
  margin: 0 auto;
  max-width: 1200px;
  padding: 6rem 6vw 10rem;
  display: flex;
  flex-direction: column;
  img {
    align-self: center;
    width: 300px;
    padding: 0 0 2rem;
    margin-top: 0;
  }
  a {
    color: ${Colours.offBlack} !important;
    position: relative;
    display: block;
    align-self: center;
    width: max-content;
    z-index: 1;
    color: inherit;
    font-size: 1.2rem;
    font-weight: bolder;
    font-family: Catamaran, sans-serif;
    background: ${Colours.secondary2};
    padding: 0.25rem 1.5rem;
    border-radius: 0.5rem;
    text-decoration: none;
  }
`;
const BigText = styled.h2`
  position: relative;

  z-index: 1;
  font-size: calc(2rem + 2vw);
  font-weight: 900;
  text-align: center;
  padding: 0 0 0.5rem;
  :before {
    content: '';
    display: inline-block;
    position: absolute;
    z-index: -1;
    right: 20%;
    top: -2rem;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: ${Colours.primary};

    @media only screen and (max-width: ${MediaQueries.insect}) {
      width: 6rem;
      height: 6rem;
      right: 15%;
      top: 1rem;
    }
  }
`;

export default ({ q, h }) => {
  return (
    <SectionContainer>
      <SectionContents>
        {q === '?success' ? (
          <BigText>Thanks, I'll be in touch!</BigText>
        ) : (
          <>
            {q === '?CountMeIn' ? (
              <BigText>If you need design, count me in!</BigText>
            ) : (
              <BigText>
                Need a{' '}
                <span
                  style={{ textDecoration: 'underline', textDecorationColor: Colours.secondary2 }}
                >
                  {q.slice(1) === 'web' ? 'web' : null}
                  {q.slice(1) === 'product' ? 'product' : null}
                  {q.slice(1) === 'graphic' ? 'graphic' : null}
                </span>{' '}
                designer?
              </BigText>
            )}
            <h3
              style={{
                position: 'relative',
                textAlign: 'center',
                fontSize: 'calc(1rem + 2vw)',
                marginBottom: '4rem',
                zIndex: '1',
              }}
            >
              You're in the right place!
            </h3>
            <Link to={q + '#howcanIhelp'}>&darr; Get in touch! &darr;</Link>
          </>
        )}
      </SectionContents>
    </SectionContainer>
  );
};
